import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import theme from "../theme/Theme";

export default ({ url }: { url: string }) => {
  // 使用 useState 管理对话框的开关状态
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    // 关闭对话框
    setOpen(false);
  };

  const handleRedirect = () => {
    // 处理跳转逻辑
    window.location.replace(`http://localhost:5173/${url}`);
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth={"sm"}>
      <DialogTitle style={{ backgroundColor: theme.palette.primary.dark }}>
        <Typography variant="h3" align="center">
          勞報單系統更新公告
        </Typography>
      </DialogTitle>
      <DialogContent>
        <div style={{margin: "30px auto"}}>
          親愛的用戶您好，<br /><br />
          感謝您一直以來的支持與愛護！<br /><br />
          為提供更穩定、流暢的使用體驗，勞報單舊系統已於 2024 年 8 月正式搬遷至全新平台，請您前往新系統網站使用。<br /><br />
          首次登入新系統時，請點選「忘記密碼」重新設定密碼，即可順利登入使用。<br /><br />
        </div>
        <Button
          variant={"contained"}
          color={"primary"}
          onClick={() => {
            // handleRedirect();
            handleClose();
          }}
        >
          前往新系統
        </Button>
      </DialogContent>
    </Dialog>
  );
};
